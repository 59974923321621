import React, { Component } from "react";
import HoldNoteCreateModal from "../../components/HoldNoteCreateModal";
import HoldNotesArchievedModal from "../../components/HoldNotesArchievedModal";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import cx from "classnames";
import moment from "moment";
import ShowMore from "react-show-more";
import MobileProductDetails from "./MobileProductDetails";
import {
  AppbaseHoldNotesApp,
  holdNoteApi,
  holdNoteApiKey,
} from "../../utils/constants";
import axios from "axios";
import PDF from "../../assets/icons/pdf-black-16.png";
import currencyFormatter from "currency-formatter";
import { getHoldNotesData } from "../../components/holdNotesApiData";
import { BaseURL } from "../../utils/constants";
import { Modal, Button } from "react-bootstrap";

class DiamondsAppResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: this.props.results,
      showHoldNotesModal: false,
      showHoldNotesEditModal: false,
      holdNotesData: "",
      showHoldNoteCreateModal: false,
      customers: "",
      holdNoteItem: "",
      showMobileProductModal: false,
      archivePopUp: false,
      blankMTSCustomer: false
    };
    this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
    // this.handleEditHoldNoteModal = this.handleEditHoldNoteModal.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.results.length !== this.state.results.length) {
      this.setState({ results: nextProps.results });
    } else if (nextProps.results.length == this.state.results.length) {
      let refreshItems = nextProps.results.every((item) =>
        this.state.results.some((data) => data._id === item._id)
      );
      if (!refreshItems) {
        this.setState({ results: nextProps.results });
      }
    }
    if (
      JSON.stringify(
        this.state.results.map((item) => {
          return item.InventoryID;
        })
      ) !==
      JSON.stringify(
        nextProps.results.map((item) => {
          return item.InventoryID;
        })
      )
    ) {
      this.setState({ results: nextProps.results });
    }
  }

  //   handleEditHoldNoteModal() {
  //     this.setState({ showHoldNotesEditModal: true });
  //   }
  handleItemHoldNoteUpdate(data) {
    this.setState((prevState) => ({
      results: prevState.results.map((result) => {
        if (result.SerialNumber === data.SerialNumber) {
          return data;
        } else {
          return result;
        }
      }),
    }));
    // this.getProduct(this.state.results);
  }

  async handleHoldNoteArchive(res) {
    //let result = window.confirm("Confirm Hold Note Archieve ?");
    if (true) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
          //blankMTSCustomer: this.state.blankMTSCustomer
        },
      };
      if(res?.MTSCustomer){
        payload.data.blankMTSCustomer = this.state.blankMTSCustomer
      }else if(res?.MTSCustomerExists === "Yes" ){
        payload.data.blankMTSCustomer =  this.state.blankMTSCustomer;
      }
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // this.props.toggleLoader({
          //   isLoading: false,
          // });
        });
      // console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        this.props.toggleLoader({
          isLoading: false,
        });
        this.setState((prevState) => ({
          results: prevState.results.map((result) => {
            if (result.SerialNumber === res.SerialNumber) {
              return {
                ...result,
                // HoldBy: null,
                HoldCustomerId: null,
                HoldCustomerName: null,
                HoldDate: null,
                HoldEnteredByID: null,
                HoldEnteredByName: null,
                HoldEnteredForID: null,
                HoldEnteredForName: null,
                HoldNoteID: null,
                HoldNoteNbr: null,
                HoldText: null,
                ReleaseDate: null,
                IsHoldSold: null,
              };
            } else {
              return result;
            }
          }),
        }));
      } else {
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }

  async handleHoldNotesModals(id, value, type, res) {
    this.setState({ holdNoteItem: res });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      this.setState({ holdNotesData: response });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    this.setState({
      ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
      ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
      ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
      ...(!value
        ? { showHoldNotesEditModal: false, holdNotesData: "", holdNoteItem: "" }
        : {}),
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  }

  render() {
    let { results } = this.state;
    //console.log("diamond related data-->",{ results });
    let {
      isEmpty,
      isValueEmpty,
      replaceDiscription,
      isMultipleValueEmpty,
      showimage,
      diaIcon,
      largeImage,
      handleMouseEnter,
      handleMouseLeave,
      nLeftPad,
      addToCart,
      setBasketFormInput,
      toggleIframeModal,
      handleSetCover,
      handleIncludeGIA,
      handleIncludeKTDShapes,
      handleEmail,
      showChooseLayout,
      showZoomImage,
      showSoldModal,
      handleShowFileIdNames,
      showStockPopUpModal,
      basketInputObj,
      feedRestrictionsData,
      jewelryRestrictionData,
      employeeData,
      showMobileProductModal,
      createStatusLink,
    } = this.props;
    return (
      <div>
        {results.map((res, index) => {
          let msgBody = `Inventory ID: ${isValueEmpty(
            res.StyleNumber
          )}%0D%0ASerial Number: ${isValueEmpty(
            res.SerialNumber
          )}%0D%0AImage: ${largeImage(
            res.LargeImageName
          )}%0D%0ADescription: ${replaceDiscription(
            res.Description
          )}%0D%0APrice: ${isValueEmpty(
            parseFloat(res.RetailPrice).toFixed(2)
          )}%0D%0A%0D%0ADETAILS:%0D%0A${isMultipleValueEmpty(
            res,
            "ItemSubType"
          )}%0D%0AMaker: ${isValueEmpty(res.Maker)}%0D%0APeriod: ${isValueEmpty(
            res.Period
          )}%0D%0ACollection: ${isValueEmpty(
            res.Collection
          )}%0D%0ALength/Size: ${isValueEmpty(res.Length)}${isValueEmpty(
            res.RingSize
          )}%0D%0ACenter Details: ${isMultipleValueEmpty(
            res,
            "CenterDetails"
          )}%0D%0A${isValueEmpty(res.Metal)} - ${isValueEmpty(
            res.DiamondCarats
          )}cts, ${isValueEmpty(res.ColorCarats)}/${isValueEmpty(res.Clarity)}`;

          return (
            <div
              style={{
                background:
                  res.IsKwiatRound === "1" ||
                  res.IsKWCushion === "1" ||
                  res.IsKwiatRadiant === "1" ||
                  res.IsKwiatEmeraldCut === "1" ||
                  res.IsKwiatOval === "1" ||
                  res.IsAshoka === "1" ||
                  res.IsFLRound === "1"
                    ? "#E3EDF3"
                    : "#f5f7f8",
              }}
              className="product"
              key={res._id}
              onMouseEnter={(e) => handleMouseEnter(e)}
              onMouseLeave={(e) => handleMouseLeave(e)}
            >
              <div className="index-share">
                <div className="product-index_container pos-relative">
                  <div className="product-index">{nLeftPad(index + 1)}</div>
                </div>
                <div className="es__product__hovered pos-relative es__bordered-box">
                  <img
                    className="es__basket-icon"
                    src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/add-to-basket.png"
                    alt="basket_icon"
                    onClick={() => {
                      addToCart({
                        product: res,
                        productType: "D",
                        assetOrmemo: basketInputObj.assetOrmemo,
                      });
                    }}
                  />

                  <img
                    src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                    alt="Email"
                    className="print__share-icon share-icons"
                    onClick={() => {
                      setBasketFormInput({
                        includePrice: false,
                        includeRetail: false,
                        includeWholesale: false,
                      });
                      setBasketFormInput({
                        includeLinks: "No",
                      });
                      handleSetCover("NoCover");
                      handleIncludeGIA("No");
                      handleIncludeKTDShapes("Yes");
                      handleEmail(res);
                    }}
                  />
                  <img
                    src={PDF}
                    alt="Email"
                    className="mail__share-icon share-icons"
                    onClick={() => {
                      setBasketFormInput({
                        includePrice: false,
                        includeRetail: false,
                        includeWholesale: false,
                      });
                      setBasketFormInput({
                        includeLinks: "No",
                      });
                      handleSetCover("NoCover");
                      handleIncludeGIA("No");
                      handleIncludeKTDShapes("Yes");
                      showChooseLayout("Print", res);
                    }}
                  />
                  {/* <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                  alt="Email"
                  className="mail__share-icon share-icons"
                  onClick={() => handleMail(msgBody)}
                />
                <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/text.svg"
                  alt="SMS"
                  className="txt__share-icons share-icons"
                  onClick={() => handleTextMsg(msgBody)}
                />
                <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/whatsapp.svg"
                  alt="Whatsapp"
                  className="whatsapp__share-icons share-icons"
                  onClick={() => handleWhatsApp(msgBody)}
                /> */}
                </div>
              </div>

              <div className="product-image_container pos-relative">
                <div className="product-image_box ">
                  <h5 className="product-info_box_heading product-dark_title_color">
                    <div>
                      <span
                        className="iframe-link"
                        onClick={() =>
                          toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                          })
                        }
                      >
                        {isValueEmpty(res.SerialNumber)}
                      </span>
                      {res.SerialVideoLink && res.SerialVideoLink !== null ? (
                        <img
                          onClick={() =>
                            toggleIframeModal({
                              show: true,
                              url: res.SerialVideoLink,
                              editorial: "",
                            })
                          }
                          className="iframe-link"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                          height="14px"
                          width="20px"
                          alt="product"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </h5>
                  <div className="product-image_background">
                    <div
                      className={
                        isValueEmpty(res.LargeImageFileID)
                          ? "product-image clickable"
                          : "product-image not-clickable"
                      }
                    >
                      {isValueEmpty(res.IconImageFileID) ? (
                        <img
                          className="product-image-icon"
                          src={showimage(res.IconImageFileID)}
                          onClick={(e) => showZoomImage(e, res)}
                          alt="product"
                        />
                      ) : (
                        // <img
                        //   className="dia-image-icon"
                        //   src={diaIcon(res.Shape)}
                        //   onClick={e => showZoomImage(e, res)}
                        //   alt="product"
                        // />
                        <img
                          className="dia-image-icon"
                          src={diaIcon(res.search_dia_image_url)}
                          onClick={(e) => showZoomImage(e, res)}
                          alt="product"
                        />
                      )}
                      <div
                        className={cx({
                          "product-status": true,
                          "text-capitalize": true,
                          RTV: res.IsRtv === "1",
                          "in-production": res.SerialStatus === "In Production",
                          adjusted: res.SerialStatus === "Adjusted Out",
                          mounted: res.IsMounted === "1",
                          sold: res.IsSold === "1",
                          "is-showcase": res.InShowCase === "1",
                        })}
                        onClick={(e) =>
                          res.IsSold === "1" ? showSoldModal(e, res) : ""
                        }
                        style={{
                          backgroundColor: res.IsRough === "1"?'#c6d5e2':'',
                          padding: res.IsRough === "1"? "0 5px" : '',
                        }}
                      >
                        {res.IsSold === "1" ? "Sold " : ""}
                        {res.IsRtv === "1" ? "RTV " : ""}
                        {res.IsRough === "1" && res.CertReceived === "0" ? "Rough " : ""}
                        {res.IsRough === "1" && res.CertReceived === "1" ? "Rough Graded" : ""}
                        {res.SerialStatus === "In Production"
                          ? "In Production"
                          : ""}
                        {res.SerialStatus === "Adjusted Out"
                          ? "Adjusted Out"
                          : ""}
                        {res.IsMounted === "1" && res.IsSold !== "1"
                          ? "mounted"
                          : ""}
                        {res.InShowCase === "1" && res.InShowCase === "1"
                          ? "In Showcase"
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section className="product-d-flex-container">
                <div className="product-details pos-relative">
                  <div className="product-info_box">
                    <h5 className="product-stylenumber_heading product-dark_title_color">
                    {/* Loose */}
                      <span
                        className="iframe-link"
                        onClick={() =>
                          toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${res.MountedInventoryID}&LotSerialNbr=${res.MountedStockNumber}`,
                          })
                        }
                      >
                        {isValueEmpty(res.MountedStockNumber)
                          ? `Mounted - ${res.MountedItemType} # ${
                              !isEmpty(res.MountedStockNumber)
                                ? res.MountedStockNumber
                                : ""
                            }`
                          : res?.crm_curent_stage}
                      </span>
                      {res?.AntiqueStyle === "1" ? <p>&nbsp; Antique</p> : ''}
                      {/*{res.IsTiara === "1" || res.IsKWCushion === "1" ? (*/}
                      {res.IsKwiatRound === "1" ||
                      res.IsKWCushion === "1" ||
                      res.IsKwiatRadiant === "1" ||
                      res.IsKwiatEmeraldCut === "1" ||
                      res.IsKwiatOval === "1" ||
                      res.IsAshoka === "1" ? (
                        <span className="es__istiara" />
                      ) : (
                        ""
                      )}
                      {res.IsFLRound === "1" ? (
                        <span className="es__isfl" />
                      ) : (
                        ""
                      )}
                      {res.FileIdNames && (
                        <img
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                          height="14px"
                          width="20px"
                          onClick={() => handleShowFileIdNames(res)}
                        />
                      )}
                      {isValueEmpty(res.TryOnPNGLink) ? (
                        <img
                          onClick={() =>
                            window.open(res.TryOnPNGLink, "_blank")
                          }
                          className="iframe-link"
                          src="https://kwiat.com/wp-content/uploads/try-on-icon-5.svg"
                          height="14px"
                          width="20px"
                          alt="product"
                        />
                      ) : (
                        ""
                      )}
                      <div className="d-flex" style={{ marginLeft: "auto" }}>
                        {res.KTDpdfurl !== "" && (
                          <span>
                            {res.KTDpdfurl && (
                              <img
                                src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/ktd-icon.png"
                                height="16px"
                                width="33px"
                                onClick={() =>
                                  toggleIframeModal({
                                    show: true,
                                    url: res.KTDpdfurl,
                                  })
                                }
                              />
                            )}
                          </span>
                        )}
                        {res.IsMTS === "1" && (
                          <span>
                            <a href={`https://minetoshine.io/journey/${res?.product_id}?mts-key=1`} target="_blank" >
                              <img
                                src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/mts-icon.png"
                                height="16px"
                                width="33px"
                                style={{ cursor: "inherit" }}
                              />
                            </a>
                          </span>
                        )}
                      </div>
                    </h5>
                    <div className="product-info_background loose-results mobile-view">
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.ShapeDisplay)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {res.ShapeDisplay && res.ShapeDisplay !== null
                                  ? `${res.ShapeDisplay}`
                                  : ""}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Shape
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.Polish)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(res, "Polish")
                                  .trim()
                                  .replace(/\s+/g, " ")}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Polish / Symmetry
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.Length)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(res, "Measurements")}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Measurements
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.GradingLab)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <div
                              className={
                                isValueEmpty(res.Giapdfurl)
                                  ? "prouct-description_info iframe-link"
                                  : "prouct-description_info"
                              }
                              onClick={() =>
                                toggleIframeModal({
                                  show: true,
                                  url: res.Giapdfurl,
                                })
                              }
                            >
                              {isMultipleValueEmpty(res, "GradingLab")}
                              {isValueEmpty(res.Giapdfurl) ? (
                                <img
                                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                  alt="icon"
                                  className="pdf-icon cursor-pointer"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <span className="product-details-view-label">
                              Lab / Report #
                            </span>
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.DiamondCaratWeight)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <div className="product-details_info">
                              <ShowMore
                                lines={1}
                                more="&#43;"
                                less="&#8722;"
                                anchorClass=""
                              >
                                {isValueEmpty(res.DiamondCaratWeight)
                                  ? `${Number(res.DiamondCaratWeight).toFixed(
                                      2
                                    )}cts`
                                  : ""}
                              </ShowMore>
                            </div>
                            <span className="product-details-view-label">
                              Carat Weight
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.StoneFluoresence)
                                ? "known product-details-label "
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(res, "StoneFluoresence")}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Fluoresence
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.StoneRatio)
                                ? "known product-details-label "
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.StoneRatio)}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Stone Ratio
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.Giashape)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.Giashape)}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              GIA Shape
                            </span>
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.DiamondClarityRange)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(res, "DiamondColorRange")}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Color / Clarity
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.DepthP)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {res.DepthP && <span className="prouct-description_info">
                                {isValueEmpty(res.DepthP)} / {isValueEmpty(res.TableP)}
                              </span>}
                            </ShowMore>
                            <span className="product-details-view-label">
                              Depth / Table
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.StoneGirdle)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.StoneGirdle)}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Girdle
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.GIAReportDate)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.GIAReportDate)
                                  ? moment(
                                      new Date(`${res.GIAReportDate}`)
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Report Date
                            </span>
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.DiamondCut)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="product-description_info">
                                {isValueEmpty(res.DiamondCut)}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Diamond Cut
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.CrownAngle)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {res.CrownAngle && <span className="prouct-description_info">
                                {res.CrownAngle} / {res.CrownHeight}
                              </span>}
                            </ShowMore>
                            <span className="product-details-view-label">
                              Crown Angle / Height
                            </span>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.StoneCulet)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span>{isValueEmpty(res.StoneCulet)}</span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Culet
                            </span>
                          </div>
                        </div>
                        {/*<div className="product-details_m-rcol">
                          <div className="unknown product-details-label">
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {res.DiamondTiaraInscription &&
                                res.DiamondTiaraInscription === true
                                  ? `Tiara Cut`
                                  : ""}
                                {res.IsFLRound && res.IsFLRound === true
                                  ? `Fred Leighton Round`
                                  : ""}
                                {res.IsFLCushion && res.IsFLCushion === true
                                  ? `Fred Leighton Cushion`
                                  : ""}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label" />
                          </div>
                        </div>*/}
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.GCalNbr)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-margin"
                            }
                          >
                            <div
                              className={
                                isValueEmpty(res.GCalpdfurl)
                                  ? "prouct-description_info iframe-link"
                                  : "prouct-description_info"
                              }
                              onClick={() =>
                                toggleIframeModal({
                                  show: true,
                                  url: res.GCalpdfurl,
                                })
                              }
                            >
                              {isMultipleValueEmpty(res, "Gcal")}
                              {isValueEmpty(res.GCalpdfurl) ? (
                                <img
                                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                  alt="icon"
                                  className="pdf-icon cursor-pointer"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <span className="product-details-view-label">
                              G-Cal Report #
                            </span>
                          </div>
                        </div>
                        {/*<div className="product-details_m-rcol">
                          <div
                          className={
                            isValueEmpty(res.KTDpdfurl)
                              ? "known product-details-label"
                              : "unknown product-details-label unknown-margin"
                          }
                        >
                          <div
                            className={
                              isValueEmpty(res.KTDpdfurl)
                                ? "prouct-description_info iframe-link"
                                : "prouct-description_info"
                            }
                            onClick={() =>
                              toggleIframeModal({
                                show: true,
                                url: res.KTDpdfurl
                              })
                            }
                          >
                            {isMultipleValueEmpty(res, "Ktd")}
                            {isValueEmpty(res.KTDpdfurl) ? (
                              <img
                                src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                alt="icon"
                                className="pdf-icon cursor-pointer"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <span className="product-details-view-label">
                          KTD Report #
                          </span>
                        </div>
                            </div>*/}
                      </section>
                    </div>
                    <MobileProductDetails
                      res={res}
                      handleProductModal={showMobileProductModal}
                    />
                  </div>
                </div>

                {/* pricing */}
                <div className="product-pricing_details pos-relative mobile-view">
                  <div className="product-info_box diamonds-status-pricing-hold-info_box">
                    <h5 className="product-info_box_heading product-light_title_color">
                      <span
                        className="pricing-title"
                        onClick={(e) => showStockPopUpModal(e, res)}
                      >
                        Pricing
                      </span>
                    </h5>
                    <div className="product-info_background">
                      <div className="product-pricing-detail">
                        <div className="product-pricing-con">
                          <p
                            className={
                              isValueEmpty(res.RetailPrice)
                                ? "known product-details-label known-status-margin-diamond"
                                : "unknown product-details-label unknown-price-margin"
                            }
                          >
                            <span className="product-details_line-break">
                              {isValueEmpty(res.RetailPrice)
                                ? currencyFormatter.format(
                                    `${res.RetailPrice} `,
                                    {
                                      code: "USD",
                                      precision: 0,
                                    }
                                  )
                                : ""}
                              {isValueEmpty(res.RetailRelToList)
                                ? ` / ${res.RetailRelToList} `
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              Retail / List
                            </span>
                          </p>
                          {basketInputObj.showWholesale && (
                            <>
                              <p
                                className={
                                  isValueEmpty(res.WholesalePrice)
                                    ? "known product-details-label known-status-margin-diamond"
                                    : "unknown product-details-label unknown-price-margin"
                                }
                              >
                                <span className="product-details_line-break">
                                  {isMultipleValueEmpty(
                                    res,
                                    "WholesalePriceCode"
                                  )}

                                  {isValueEmpty(res.WholesaleRelToList)
                                    ? ` / ${res.WholesaleRelToList} `
                                    : ""}
                                </span>
                                <span className="product-details-view-label">
                                  Wholesale / List
                                </span>
                              </p>
                              <p
                                className={
                                  isValueEmpty(res.WholesalePerCarat)
                                    ? "known product-details-label known-status-margin-diamond"
                                    : "unknown product-details-label unknown-price-margin"
                                }
                              >
                                <span className="product-details_line-break">
                                  {isMultipleValueEmpty(
                                    res,
                                    "WholesalePerCaratCode"
                                  )}
                                </span>

                                <span className="product-details-view-label">
                                  Wholesale PPC
                                </span>
                              </p>
                            </>
                          )}
                          <p
                            className={
                              isValueEmpty(res.PricingDate)
                                ? "known product-details-label known-status-margin-diamond"
                                : "unknown product-details-label unknown-price-margin"
                            }
                          >
                            <span className="product-details_line-break">
                              {isValueEmpty(res.PricingDate)
                                ? moment(new Date(`${res.PricingDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              Price Date
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* status */}
                <div className="product-status_details pos-relative mobile-view">
                  <div className="product-info_box diamonds-status-pricing-hold-info_box">
                    <h5 className="product-info_box_heading product-light_title_color">
                      <span>Status</span>
                    </h5>
                    <div className="product-info_background">
                      <div className="product-status-detail">
                        <div className="product-status-con">
                          <div
                            className={
                              isValueEmpty(res.SerialStatus)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-status-margin-diamond"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="status product-details_line-break ">
                                {isValueEmpty(res.Warehouse)}
                                {res.Warehouse !== res.SerialStatus &&
                                isValueEmpty(res.Warehouse) &&
                                isValueEmpty(res.SerialStatus)
                                  ? "/"
                                  : ""}
                                {res.IsVirtual === "1" ? "Virtual - " : ""}
                                {res.IsPhantom === "1" ? "Phantom - " : ""}
                                {res.Warehouse !== res.SerialStatus &&
                                  isValueEmpty(res.SerialStatus)}
                                {res.MemoClass && " - "+res.MemoClass}
                              </span>
                            </ShowMore>
                            <span className="product-details-view-label">
                              Status
                            </span>
                          </div>
                          <div
                            className={
                              isValueEmpty(res.StatusCustomer)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-status-margin-diamond"
                            }
                          >
                            <div
                              className="location iframe-link"
                              onClick={() =>
                                toggleIframeModal({
                                  show: true,
                                  url: `${BaseURL}/pages/kw/kw501003.aspx?PopupPanel=On&AcctCD=${res.StatusCustomerID}`,
                                })
                              }
                            >
                              <ShowMore
                                lines={1}
                                more="&#43;"
                                less="&#8722;"
                                anchorClass=""
                              >
                                {res.MemoOutCustomer
                                  ? isValueEmpty(res.MemoOutCustomer)
                                  : res.StatusCustomer
                                  ? isValueEmpty(res.StatusCustomer)
                                  : ""}
                              </ShowMore>
                            </div>
                            <span className="product-details-view-label">
                              Customer
                            </span>
                          </div>
                          <div
                            className={
                              isValueEmpty(res.StatusDate)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-status-margin-diamond"
                            }
                          >
                            <div>
                              {isValueEmpty(res.StatusRefNbr) &&
                              isValueEmpty(res.StatusDate) ? (
                                <span
                                  className="iframe-link"
                                  onClick={() =>
                                    toggleIframeModal({
                                      show: true,
                                      url: `${BaseURL}${createStatusLink(
                                        res.SerialStatus
                                      )}${res.StatusRefNbr}`,
                                    })
                                  }
                                >
                                  {isValueEmpty(res.StatusDate)
                                    ? moment(
                                        new Date(`${res.StatusDate}`)
                                      ).format("MM/DD/YYYY")
                                    : ""}
                                  &nbsp;-&nbsp;{res.StatusRefNbr}
                                </span>
                              ) : (
                                ""
                              )}
                              {isValueEmpty(res.StatusDate) && !res.StatusRefNbr
                                ? moment(new Date(`${res.StatusDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </div>
                            <span className="product-details-view-label">
                              Date/Ref #
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Hold Notes */}
                <div className="holding-details pos-relative mobile-view">
                  <div className="product-info_box diamonds-status-pricing-hold-info_box">
                    <h5 className="product-info_box_heading product-light_title_color">
                      <span
                        onClick={() =>
                          toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                          })
                        }
                      >
                        Hold Notes
                      </span>
                    </h5>
                    {/* holdNotesAccess */}
                    {basketInputObj.holdNotesAccess && (
                      <div class="hold_notes_actions">
                        {!res._id.toString().includes("JOB") && res.HoldNoteID && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Edit">
                                Edit
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() => {
                                this.setState({ showHoldNotesEditModal: true });
                                this.handleHoldNotesModals(
                                  "",
                                  true,
                                  "createHoldNotes",
                                  res
                                );
                              }}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </OverlayTrigger>
                        )}
                        {!res._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Create">
                                Create
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() =>
                                this.handleHoldNotesModals(
                                  res.InventoryID.toString() +
                                    res.SerialNumber.toString(),
                                  true,
                                  "createHoldNotes",
                                  res
                                )
                              }
                            >
                              +
                            </span>
                          </OverlayTrigger>
                        )}
                        {res.HoldNoteID && !res._id.toString().includes("JOB") && (
                          <>
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Archive">
                                Archive
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() => {
                                if(res?.MTSCustomer){
                                  this.setState({
                                    ...this.state,
                                    archivePopUp: true
                                  })
                                }
                                else{
                                  this.handleHoldNoteArchive(res)
                                }
                                // if(!this.state.archivePopUp) this.handleHoldNoteArchive(res)
                              }}
                            >
                              x
                            </span>
                            
                          </OverlayTrigger>
                          <Modal
                          show={this.state.archivePopUp}
                          onHide={() => {}}
                          animation={false}
                          // dialogClassName="show-more_modal-dailog"
                          className="hold_notes_create_modal"
                          aria-labelledby="contained-modal-title-vcenter"
                          style={{
                            position: "fixed",
                            left: "20%",
                            top: "20%",
                            width: "60%",
                          }}
                        >
                          <Modal.Body>
                            <div>
                              <p>Do you want to blank out MTS Customer ?</p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                }}
                              >
                                <Button
                                  variant="secondary"
                                  style={{ marginRight: "2vw" }}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        ...this.state,
                                        archivePopUp: false,
                                        blankMTSCustomer: false,
                                      },
                                      () => {
                                        if (res)
                                          this.handleHoldNoteArchive(res);
                                      }
                                    );
                                  }}
                                >
                                  NO
                                </Button>
                                <Button
                                  variant="secondary"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        ...this.state,
                                        archivePopUp: false,
                                        blankMTSCustomer: true,
                                      },
                                      () => {
                                        if (res)
                                          this.handleHoldNoteArchive(res);
                                      }
                                    );
                                  }}
                                >
                                  YES
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                          </>
                        )}

                        {!res._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="View">
                                View
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={(e) =>
                                this.handleHoldNotesModals(
                                  res.InventoryID.toString() +
                                    res.SerialNumber.toString(),
                                  true,
                                  "archivedHoldNotes",
                                  ""
                                )
                              }
                            >
                              <i class="fa fa-history" aria-hidden="true"></i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                    <div className="product-info_background">
                      <section className="responsive-hold-notes">
                        <div className="product-details_lcol">
                          <div className="unknown product-details-label">
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="entered-by">
                                {isValueEmpty(res.HoldEnteredByName)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div className="unknown product-details-label">
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="customer-name">
                                {isValueEmpty(res.HoldCustomerName)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                      </section>
                      <section className="responsive-hold-notes">
                        <div className="product-details_lcol">
                          <div className="unknown product-details-label">
                            <span className="hold-date">
                              {isValueEmpty(res.HoldDate)
                                ? moment(new Date(`${res.HoldDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.HoldDate) ? " Hold Date" : ""}
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div className="unknown product-details-label">
                            <span className="release-date">
                              {isValueEmpty(res.ReleaseDate)
                                ? moment(new Date(`${res.ReleaseDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.ReleaseDate)
                                ? " Release Date"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </section>
                      <div className="holding-description">
                        <ShowMore
                          lines={3}
                          more="&#43;"
                          less="&#8722;"
                          anchorClass="holding-description_accordion"
                        >
                          <span className="holding-con">
                            {isValueEmpty(res.HoldText)}
                          </span>
                        </ShowMore>
                      </div>
                      {res.IsHoldSold === "1" && (
                        <div className="is_hold_sold">SOLD</div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        })}
        <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={feedRestrictionsData}
          jewelryRestrictionData={jewelryRestrictionData}
          employeeData={employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
        />
      </div>
    );
  }
}

export default DiamondsAppResults;
